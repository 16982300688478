import React, { useState, useContext, useEffect } from 'react'
import { graphql, navigate } from 'gatsby'
import { ImageDataLike, getImage } from 'gatsby-plugin-image'
import CheckoutContext from '../../context/CheckoutContext'
import LocaleContext from '../../context/LocaleContext'
import PageContext from '../../context/PageContext'
import { Locale, localisePath, localiseMoney } from '../../domain/locale'
import { PRODUCTS_PATH } from '../../domain/path'
import { Product, createProductUrl } from '../../domain/product'
import Layout from '../../components/layout/Layout'
import Seo from '../../components/layout/Seo'
import PhotoGrid from '../../components/product/PhotoGrid'
import AddToCartButton from '../../components/product/AddToCartButton'

export const productQuery = graphql`
    query AUProductDetailQuery($title: String!, $category: String!) {
        product: shopifyProduct(title: { eq: $title }) {
            id
            title
            descriptionHtml
            images {
                id
                localFile {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
                altText
            }
            variants {
                id
                price
                inventoryQuantity
                storefrontId
            }
        }
        categoryProducts: allShopifyProduct(
            filter: { productType: { eq: $category } }
        ) {
            edges {
                node {
                    title
                }
            }
        }
    }
`

type ShopifyImage = {
    id: string
    localFile: ImageDataLike
    altText: string
}

type ShopifyProductVariant = {
    id: string
    price: number
    inventoryQuantity: number
    storefrontId: string // storefrontId is the correct `variantId` is used for checkout purposes, not id
}

type ShopifyProductTitleEdge = {
    node: {
        title: string
    }
}

type ShopifyProductData = {
    product: {
        id: string
        title: string
        descriptionHtml: string
        images: ShopifyImage[]
        variants: ShopifyProductVariant[]
    }
    categoryProducts: {
        edges: ShopifyProductTitleEdge[]
    }
}

type Props = {
    data: ShopifyProductData
}

function getDomainProduct(data: ShopifyProductData): Product {
    const { product, categoryProducts } = data

    // Note that all products only have 1 variant (itself)
    const variant = product.variants[0]

    // Re-order the category product titles in ascending order
    const categoryProductTitles = categoryProducts.edges.map(
        edge => edge.node.title
    )
    categoryProductTitles.sort()

    return {
        id: product.id,
        name: product.title,
        descriptionHtml: product.descriptionHtml,
        images: product.images.map(image => ({
            id: image.id,
            data: getImage(image.localFile),
            altText: image.altText,
        })),
        locale: Locale.AU,
        url: localisePath(createProductUrl(product.title), Locale.AU),
        productVariant: {
            variantId: variant.storefrontId,
            price: variant.price,
            inventoryQuantity: variant.inventoryQuantity,
        },
        categoryProducts: categoryProductTitles.map(title => ({
            name: title,
            url: localisePath(createProductUrl(title), Locale.AU),
        })),
    }
}

function ProductPage({ data }: Props) {
    // Set locale at the start
    const { setLocale } = useContext(LocaleContext)
    useEffect(() => {
        setLocale(Locale.AU)
    }, [])

    const { lineItems } = useContext(CheckoutContext)
    const [quantity, setQuantity] = useState(1)

    const product = getDomainProduct(data)
    const lineItem = lineItems.find(
        item => item.variantId === product.productVariant.variantId
    )
    const lineItemQuantity = !!lineItem ? lineItem.quantity : 0
    const addableQuantity =
        product.productVariant.inventoryQuantity - lineItemQuantity

    return (
        <PageContext.Provider
            value={{
                basepath: PRODUCTS_PATH,
                locale: Locale.AU,
                localised: true,
            }}
        >
            <Layout>
                <Seo
                    title={product.name}
                    titleCompany="Telobag AU"
                    description={product.name}
                    relativeUrl={product.url}
                />
                <div className="lg:flex -mx-6">
                    {/* Left column */}
                    <div className="sm:flex sm:flex-row mb-8 px-6 md:mb-0 lg:w-1/2">
                        <PhotoGrid images={product.images} />
                    </div>

                    {/* Right column */}
                    <div className="px-6 md:py-3 lg:w-1/2">
                        {/* Name */}
                        <h1 className="font-bold text-3xl md:text-5xl mb-3 text-primary leading-tight">
                            {product.name}
                        </h1>

                        {/* Price */}
                        <div className="mb-6 flex flex-row">
                            <p className="font-semibold text-2xl text-slategray">
                                {localiseMoney(
                                    product.productVariant.price,
                                    Locale.AU
                                )}
                            </p>
                            {addableQuantity < 10 && (
                                <span className="inline-flex items-center ml-4 px-2.5 py-0.5 rounded-lg text-sm font-semibold bg-yellow-100 text-yellow-800">
                                    {addableQuantity > 0
                                        ? `${addableQuantity}`
                                        : 0}{' '}
                                    left in stock
                                </span>
                            )}
                        </div>

                        {/* Description */}
                        <div className="mb-6">
                            <p
                                className="leading-loose text-lightgray"
                                dangerouslySetInnerHTML={{
                                    __html: product.descriptionHtml,
                                }}
                            />
                        </div>

                        {/* Interactive section: Selections */}
                        <div className="md:flex md:flex-wrap -mx-3 mb-8">
                            {/* Selection to go to other variants */}
                            <div className="md:w-3/4 px-3 mb-6">
                                <label
                                    className="block text-sm font-bold tracking-widest uppercase mb-2"
                                    htmlFor="size"
                                >
                                    Variants
                                </label>

                                <div className="relative">
                                    <select
                                        id="size"
                                        value={product.url}
                                        onChange={({ target: { value } }) =>
                                            navigate(value, {
                                                replace: true,
                                            })
                                        }
                                        className="block appearance-none w-full bg-gainsboro border-2 border-gainsboro focus:border-primary focus:ring-primary px-4 py-3 pr-8 focus:outline-none focus:bg-white rounded-lg"
                                    >
                                        {product.categoryProducts.map(
                                            categoryProduct => (
                                                <option
                                                    key={categoryProduct.url}
                                                    value={categoryProduct.url}
                                                >
                                                    {categoryProduct.name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>

                            {/* Button to select item quantity to be added */}
                            <div className="md:w-1/4 px-3 mb-6">
                                <label
                                    className="block text-sm font-bold tracking-widest uppercase mb-2"
                                    htmlFor="quantity"
                                >
                                    Quantity
                                </label>

                                <div className="relative">
                                    <select
                                        id="quantity"
                                        value={quantity}
                                        className="block appearance-none w-full bg-gainsboro border-2 border-gainsboro focus:border-primary focus:ring-primary px-4 py-3 pr-8 focus:outline-none focus:bg-white rounded-lg"
                                        onChange={({ target: { value } }) =>
                                            setQuantity(parseInt(value))
                                        }
                                    >
                                        {new Array(5)
                                            .fill(0)
                                            .map((v, k) => k + 1)
                                            .map(i => ({ value: i, label: i }))
                                            .map(({ value, label }) => (
                                                <option
                                                    key={value}
                                                    value={value}
                                                >
                                                    {label}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        {/* Interactive section: Add to Cart button */}
                        <div className="mb-6">
                            <AddToCartButton
                                variantId={product.productVariant.variantId}
                                quantity={quantity}
                                disabled={quantity > addableQuantity}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        </PageContext.Provider>
    )
}

export default ProductPage
