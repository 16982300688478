import { IGatsbyImageData } from 'gatsby-plugin-image'
import { Locale } from './locale'
import { PRODUCTS_PATH } from './path'

type ProductImage = {
    id: string
    data: IGatsbyImageData
    altText: string
}

type ProductCategory = {
    id: string
    name: string
    image: ProductImage
    featuredProductUrl: string
}

// While our Telo Carrier Size 17 and 30 should be variants of Telo Carrier,
// we have decided to separate them into individual products, with each one
// having only one variant (default variant). This is because each size has
// different pictures and descriptions of suitable use cases (e.g. 17 for
// small accessories and 30 for shopping bags), which is different from
// products like T-shirts, where the variant represents the size.
// Further convention: variant here means the physical "product variant"
// in warehouse.
type Product = {
    id: string
    name: string
    descriptionHtml: string
    images: ProductImage[]
    locale: Locale
    url: string
    productVariant: ProductVariant // denotes the "physical" variant in warehouse
    categoryProducts: CategoryProduct[] // other products that are of same category as the current product
}

type ProductVariant = {
    variantId: string
    price: number
    inventoryQuantity: number
}

type CategoryProduct = {
    productId?: string
    name: string
    url: string
}

function createProductSlug(productName: string): string {
    // Make everything lower-case
    const name = productName.toLowerCase()

    // Tokenise words based on whitespace
    const tokens = name.split(' ')

    // If the first token is `telo`, e.g. Telo Carrier, we want the slug "telocarrier".
    // Otherwise, the tokens don't need to be changed
    let slugTokens = []
    if (tokens[0] === 'telo') {
        slugTokens.push(tokens[0] + tokens[1])
        slugTokens.push(...tokens.slice(2))
    } else {
        slugTokens = tokens
    }

    return slugTokens.join('-')
}

function createProductUrl(productName: string): string {
    return `${PRODUCTS_PATH}/${createProductSlug(productName)}`
}

export { ProductImage, ProductCategory, Product, createProductUrl }
