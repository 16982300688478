import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import classnames from 'classnames'
import { ProductImage } from '../../domain/product'

type Props = {
    images: ProductImage[]
}

function PhotoGrid({ images }: Props) {
    const [activeImage, setActiveImage] = useState(images[0])

    return (
        <React.Fragment>
            {/* How to make column-based photo grid: wrap the main image and other images as "flex-row".
                Then, set the widths (3/4 for main and 1/4 for images). Lastly, for the images, set them
                as "flex-col" so they stack up. And, most important, change each image's "w-1/4" to "h-1/4"*/}
            {/* How to make row-based photo grid: Main image has normal width (w-full). Then, for the images,
                set each image's width to be "w-1/4" */}
            <div className="sm:w-3/4 w-full bg-white overflow-hidden relative rounded-lg">
                <GatsbyImage
                    image={activeImage.data}
                    alt={activeImage.altText}
                />
            </div>
            {/* When doing column-based photo grid (sm above), add left margin "ml-2" */}
            <section className="sm:w-1/4 sm:ml-2 py-4 px-4">
                <div className="flex sm:flex-col flex-wrap -mx-4">
                    {images.map(image => {
                        const isActiveImage = image.id === activeImage.id
                        const imageClass = classnames(
                            'sm:h-1/4 sm:w-auto w-1/4 px-4 mb-4 rounded-lg',
                            {
                                'border-4': isActiveImage,
                                'border-slategray': isActiveImage,
                            }
                        )
                        return (
                            <button
                                key={image.id}
                                className={imageClass}
                                onClick={() => setActiveImage(image)}
                            >
                                <GatsbyImage
                                    image={image.data}
                                    alt={image.altText}
                                />
                            </button>
                        )
                    })}
                </div>
            </section>
        </React.Fragment>
    )
}

export default PhotoGrid
