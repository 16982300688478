import React, { useContext } from 'react'
import classNames from 'classnames'
import CheckoutContext from '../../context/CheckoutContext'

type Props = {
    variantId: string
    quantity: number
    disabled: boolean
}

function AddToCartButton({ variantId, quantity, disabled }: Props) {
    const { addLineItem, loading } = useContext(CheckoutContext)

    async function handleAddItem() {
        await addLineItem(variantId, quantity)
    }

    const buttonClass = classNames(
        'block w-full  px-4 py-3 rounded-lg text-sm font-bold tracking-widest uppercase focus:outline-none',
        {
            'bg-primary hover:bg-primary_dark text-white': !disabled,
            'bg-gray-100 text-gray-800 cursor-not-allowed': disabled,
        }
    )

    return (
        <button
            className={buttonClass}
            onClick={handleAddItem}
            disabled={disabled}
        >
            {loading ? <p>Loading...</p> : <p>Add to cart</p>}
        </button>
    )
}

export default AddToCartButton
